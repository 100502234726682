import Auth0Lock from 'auth0-lock';
import history from './history';
import { Auth0Config } from '../../settings';
import { notification } from '../../components';
import axios from "axios";
import { apiUrl } from '../../settings';

class Auth0Helper {
  isValid = Auth0Config.clientID && Auth0Config.domain;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }
  async login(handleLogin) {
    const unAuthOptions = {
      headers: {
          'Content-Type': 'application/json'
      }
  };

  try {
      const res = await axios
          .post(apiUrl+"v1/auth/influencers/login", {
              "email": handleLogin.username,
              "password": handleLogin.password
          }, unAuthOptions);
      if (res.data.token) {
        localStorage.setItem('id_token', res.data.token);
          const now = new Date()
          const item = {
              value: res.data.token,
              email: handleLogin.username,
              expiry: now.getTime() + 5000,
              name:res.data.firstName+" "+res.data.lastName,
              uid:res.data.userId
          }
          localStorage.setItem('access_token', res.data.token);
          localStorage.setItem('expires_at', now.getTime() + 5000);
          localStorage.setItem("infAuthKey", JSON.stringify(item));
      }
      return res.data;
  } catch (err) {
      console.log(err);
      return ""
  }
  }
  handleAuthentication(props) {
    localStorage.setItem('id_token', 'secret token');
    history.replace('/influencers/campaigns');
  }
  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    history.replace('/');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    return (
      new Date().getTime() < JSON.parse(localStorage.getItem('expires_at'))
    );
  }

  async forgotPassword (payload) {
    try {
      const res = await axios
        .post(apiUrl + "v1/auth/influencers/forgotPassword", payload, {
          headers: {
              'Content-Type': 'application/json'
          }
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return ""
    }
}
}
export default new Auth0Helper();
