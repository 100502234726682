import { getDefaultPath } from '../../helpers/urlSync';
import getDevSidebar from '../../customApp/sidebar';

const options = [
  {
    label: 'sidebar.campaign',
    key: 'campaigns',
    leftIcon: 'campaign',

  },{
    label: 'sidebar.profile',
    key: 'basic',
    isNavTab: true,
    leftIcon: 'person',
    children: [
      {
        label: 'sidebar.basicProfile',
        key: 'basic',
        default: true,
      },
      {
        label: 'sidebar.socialProfile',
        key: 'social',
      },
    ],
  },
  ...getDevSidebar,
];
const getBreadcrumbOption = () => {
  const preKeys = getDefaultPath();
  let parent, activeChildren;
  options.forEach(option => {
    if (preKeys[option.key]) {
      parent = option;
      (option.children || []).forEach(child => {
        if (preKeys[child.key]) {
          activeChildren = child;
        }
      });
    }
  });
  return { parent, activeChildren };
};
export default options;
export { getBreadcrumbOption };
