import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { getCurrentUser } from '../../helpers/utility';
import Button from '../../components/uielements/button';
import { Form, TextField } from '../../components/billingForm/billingForm.style';
import { apiUrl } from '../../settings';
import { getToken } from '../../helpers/utility';
import Snackbar from '../../containers/AdvanceUI/Snackbar/style';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/uielements/dialogs';
import {
  IconButtons,
  TopbarDropdown,
  UserInformation,
  SettingsList,
  Icon,
} from './topbarDropdown.style';
import authAction from '../../redux/auth/actions';

const { logout } = authAction;

class TopbarUser extends Component {
  userData = getCurrentUser();
  state = {
    visible: false,
    anchorEl: null,
    email: this.userData.email,
    name: this.userData.name,
    open: false,
    currentPassword: "",
    newPassword: "",
    confPassword: "",
    isOpen: false,
    message: "Password changed successfully!",
    snackbar: false,
    passwordError: "",
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.newPassword !== this.state.confPassword) {
      this.setState({ passwordError: 'Passwords do not match' });
      return;
    }
    let payload = { "oldPassword": this.state.currentPassword, "newPassword": this.state.newPassword, "email": this.userData.email }
    var retData = this.changePassword(payload)
    console.log(payload);

  }
  changePassword = async (payload) => {
    console.log(payload)
    return await axios
      .post(apiUrl + "influencers/changePassword", payload, {})
      .then((res) => {
        console.log(res)
        this.setState({ isOpen: false, message: res.data.message, visible: false, open: false, snackbar: true });
        if (res.data.message == "Password Changed Sucessfully!") {
          this.props.logout();
        }
        return res.data;
      })
      .catch((err) => {
        this.setState({ isOpen: false, message: err, visible: false, open: false, snackbar: true });
        console.log(err);
      });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleRequestCloseSnackbar = () => {
    this.setState({ isOpen: false });
    this.setState({ open: false });
    this.setState({ snackbar: false });
    window.location.reload();
  };
  handleRequestClose = () => {
    this.setState({ isOpen: false });
    this.setState({ open: false });
    this.setState({ snackbar: false });
  };
  

  hide = () => {
    this.setState({ visible: false });
  };
  handleVisibleChange = () => {
    this.setState({
      visible: !this.state.visible,
      anchorEl: findDOMNode(this.button),
    });
  };
  render() {
    const content = (
      <TopbarDropdown>
        <UserInformation>
          {/*<div className="userImage">
            <img src={Image} alt="user" />
    </div>*/}

          <div className="userDetails">
            <h3>{this.state.name}</h3>
            <p>{this.state.email}</p>
          </div>
        </UserInformation>

        <SettingsList>
          <a href="basic" className="dropdownLink">
            <Icon>person</Icon>
            <IntlMessages id="sidebar.profile" />
          </a>
          <a href="#!" onClick={this.handleClickOpen} className="dropdownLink">
            <Icon>lock</Icon>
            <IntlMessages id="sidebar.resetPassword" />
          </a>

          <Link to="/" onClick={this.props.logout} className="dropdownLink">
            <Icon>input</Icon>
            <IntlMessages id="topbar.logout" />
          </Link>
        </SettingsList>
      </TopbarDropdown>
    );
    return (
      <div id="topbarUserIcon">
        <IconButtons
          ref={node => {
            this.button = node;
          }} 
          style={{color:"black",padding:0,marginBottom:"-15px"}}
          onClick={this.handleVisibleChange}
        >
          <div className="userImgWrapper" >
            <Icon>person</Icon>
          </div>
        </IconButtons>

        <TopbarDropdownWrapper
          open={this.state.visible}
          anchorEl={this.state.anchorEl}
          onClose={this.hide}
          // marginThreshold={66}
          className="userPopover"
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          {content}
        </TopbarDropdownWrapper>

        <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <Form onSubmit={this.handleSubmit}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <TextField
                id="currentPassword"
                label="Current Password"
                type="password"
                value={this.state.currentPassword}
                onChange={this.handleChange('currentPassword')}
                required
                fullWidth
              />
              <TextField
                id="newPassword"
                label="New Password"
                type="password"
                value={this.state.newPassword}
                onChange={this.handleChange('newPassword')}
                required
                fullWidth
              />
              <TextField
                id="confPassword"
                label="Confirm New Password"
                type="password"
                value={this.state.confPassword}
                onChange={this.handleChange('confPassword')}
                required
                fullWidth
              />
              {this.state.passwordError && <div style={{ color: 'red' }}>{this.state.passwordError}</div>}
            </DialogContent>
            <div style={{ width: "100%", float: "right" }}>
              <DialogActions>
                <Button color="primary" onClick={this.handleRequestClose}>
                  Cancel
                </Button>
                <Button color="primary" type='submit'>
                  Change Password
                </Button>
              </DialogActions>                </div>

          </Form>
        </Dialog>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.handleRequestCloseSnackbar}
          open={this.state.snackbar} >
          <Alert severity="success" onClose={this.handleRequestCloseSnackbar}
          >{<span id="message-id">{this.state.message}</span>}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
  }),
  { logout }
)(TopbarUser);
