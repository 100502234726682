import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import signinImg from '../../../images/signin.svg';
import TextField from '../../../components/uielements/textfield';
import Scrollbars from '../../../components/utility/customScrollBar';
import Button from '../../../components/uielements/button';
import authAction from '../../../redux/auth/actions';
import SignUpStyleWrapper from './signup.style';
import { Form } from '../../../components/billingForm/billingForm.style';
import axios from 'axios';
import { apiUrl } from '../../../settings';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


const { login } = authAction;

class SignUp extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const registerCode = query.get('code')
    this.state = {
      firstName: "", 
      lastName: "", 
      email: "", 
      password: "",
      registerCode:registerCode,
      cpassword:"",
      passwordError:"",
      showPassword:false,
      showCnfPassword:false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    redirectToReferrer: false,
  };

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  toggleCnfPasswordVisibility = () => {
    this.setState({ showCnfPassword: !this.state.showCnfPassword });
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.setState({ redirectToReferrer: true });
    }
  }

  handleChange=(name)=>(event) =>{
    this.setState({
      [name]: event.target.value,
    });
  }
  handleLogin = (event) => {
    event.preventDefault();
    if(this.state["password"]!== this.state["cpassword"]) {
      this.setState({ passwordError: 'Passwords do not match' });
      return;
    }
    let payload = {
      "firstName": this.state["firstName"],
      "lastName": this.state["lastName"],
      "email": this.state["email"],
      "password": this.state["password"],
      "active" : true,
      "registerCode" : this.state["registerCode"]
    }
    this.AddInfluencer(payload)
  }
    AddInfluencer = async (payload) => {
    return await axios
      .post(apiUrl+"v1/auth/influencers/register", payload, {})
      .then((res) => {
        if(res.data) {
          this.props.history.push('/signin');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
   // this.props.history.push('/influencers/campaigns');

  render() {
    return (
      <SignUpStyleWrapper className="mateSignUpPage">
        <div className="mateSignInPageImgPart">
          <div className="mateSignInPageImg">
            <img src={signinImg} alt="Kiwi standing on oval" />
          </div>
        </div>

        <div className="mateSignInPageContent">
          
          <Scrollbars style={{ height: '100%' }}>
            <div className="mateSignInPageGreet">
              <h1>Its Free, Join Us</h1>
              <p>
                Welcome to Influencer Platform, Please SignUp with your personal account
                information.
              </p>
            </div>
            <Form onSubmit={this.handleLogin}>
       
            <div className="mateSignInPageForm">
              <div className="mateInputWrapper">
                <TextField
                  required
                  label="First Name"
                  placeholder="First Name"
                  margin="normal"
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}  

                />
              </div>
              <div className="mateInputWrapper">
                <TextField
                  required
                  label="Last Name"
                  placeholder="Last Name"
                  margin="normal"
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                />
              </div>
              <div className="mateInputWrapper">
                <TextField
                  required
                  label="Email"
                  placeholder="Email"
                  margin="normal"
                  type="Email"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                />
              </div>
              <div className="mateInputWrapper">
                <TextField
                  required
                  label="Password"
                  placeholder="Password"
                  margin="normal"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.togglePasswordVisibility}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="mateInputWrapper">
                <TextField
                  required
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  margin="normal"
                  type={this.state.showCnfPassword ? 'text' : 'password'}
                  value={this.state.cpassword}
                  onChange={this.handleChange('cpassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.toggleCnfPasswordVisibility}>
                          {this.state.showCnfPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            {this.state.passwordError && <div style={{ color: 'red' }}>{this.state.passwordError}</div>}
            <div className="mateAgreement">
              <div className="mateLoginSubmit">
                <Button type="submit">
                  Sign Up
                </Button>
              </div>
            </div>
            </Form>
          </Scrollbars>
        </div>
      </SignUpStyleWrapper>
    );
  }
}

export default connect(
  state => ({
    isLoggedIn: localStorage.getItem('id_token') !== null ? true : false,
  }),
  { login }
)(SignUp);
